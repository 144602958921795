import { CategoryMaster } from './../../../models/inventory/category/category-master-model';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetCategory } from '../../../interfaces/inventory/category/category';
import { IGetCategoryData } from '../../../interfaces/inventory/category/category-data';
import { ICommonResponse } from '../../../interfaces/common/common-response';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;



  getCategoryList(id: Number): Observable<IGetCategory> {
    const url = this.serverEndPoint + 'getCategory?category_id=' + id;
    return this.http.get<IGetCategory>(url);
  }

  addUpdateCategory(categoryModel: CategoryMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateInventoryCategory';
    console.log(url,',odel',categoryModel)
    return this.http.post<ICommonResponse>(url, categoryModel)
  }

}
