import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetVendor } from 'src/app/core/interfaces/purchase/purchase-order/vendor';
import { IGetVendorByBrand } from 'src/app/core/interfaces/purchase/purchase-order/brand';
import { IGetProductByVendorAndBrnad } from 'src/app/core/interfaces/purchase/purchase-order/get-product';
import { PurchaseMaster } from 'src/app/core/models/purchase/purchase-order/purchase-order-model';
import { IGetPoTable } from 'src/app/core/interfaces/purchase/purchase-order/get-po-table';
import { IGetPoDetails } from 'src/app/core/interfaces/purchase/purchase-order/get-po-details';
import { IProductMaster } from 'src/app/core/models/purchase/purchase-order/product-modal-model';
import { IPODeleteTableModel } from 'src/app/core/models/purchase/purchase-order/po-delete-tableData-model';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetInwardDetails } from 'src/app/core/interfaces/purchase/inward/inward-details';
import { IGetInwardStockReceived } from 'src/app/core/interfaces/purchase/purchase-order/get-inwardStock-received';
import { poForceClosedModel } from 'src/app/core/models/purchase/purchase-order/po-forceClosedModel';



@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getVendorList(id: Number): Observable<IGetVendor> {
    const url = this.serverEndPoint + 'GetVendorForTable?vendorId=' + id;
    return this.http.get<IGetVendor>(url);
  }

  getVendorByBrandList(id: Number): Observable<IGetVendorByBrand> {
    const url = this.serverEndPoint + 'GetBrandByVendor?vendorId=' + id;
    return this.http.get<IGetVendorByBrand>(url);
  }

  getProductByVendorAndBrand(vendorId:Number, brandId:Number):Observable<IGetProductByVendorAndBrnad>{
    const url = this.serverEndPoint + 'GetProductByBrandAndVendor?vendorId=' + vendorId +'&brandId=' + brandId;
    return this.http.get<IGetProductByVendorAndBrnad>(url);
  }

  addUpdatePurchase(purchaseModel: PurchaseMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'InsertPurchaseOrder';
    console.log(url,',odel',purchaseModel)
    return this.http.post<ICommonResponse>(url, purchaseModel)
  }

   getPoTableList(id: Number):Observable<IGetPoTable>{
     const url = this.serverEndPoint + 'getPurchaseOrderForTable?statusEnumId=' +id
     return this.http.get<IGetPoTable>(url);
   }

   getPoDetailsList(poId: Number,statusEnumId: Number):Observable<IGetPoDetails>{
    const url = this.serverEndPoint + 'getPurchaseOrderDetail?poId=' + poId +'&statusEnumId=' + statusEnumId
    return this.http.get<IGetPoDetails>(url);
  }

  addNewProduct(productModel: IProductMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'insertProductGeneralInfoToPO';
    console.log(url,',odel',productModel)
    return this.http.post<ICommonResponse>(url, productModel)
  }

  deletePoTableData(deletePOModel: IPODeleteTableModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'deletePurchaseOrderDetail';
    console.log(url,',odel',deletePOModel)
    return this.http.post<ICommonResponse>(url,deletePOModel)
  }

  getInwardCardDetails(statusEnumId: Number,poId: Number):Observable<IGetInwardDetails>{
      const url = this.serverEndPoint + 'getInwardPOWise?statusEnumId=' + statusEnumId +'&POId=' + poId
      return this.http.get<IGetInwardDetails>(url);
    }
  

  getShopList(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }

  getInwardStockReceived(poId: Number,productId: Number):Observable<IGetInwardStockReceived>{
    const url = this.serverEndPoint + 'getInwardStockRecived?statusEnumId=1'+ '&poId=' + poId +'&productId=' + productId
    return this.http.get< IGetInwardStockReceived>(url);
  }
  


  purchaseOrderForceFulyClosed(ForceFulyClosedModel:poForceClosedModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'purchaseOrderForceFulyClosed';
    return this.http.post<ICommonResponse>(url, ForceFulyClosedModel);
  }

}