
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetCompanyDetails } from '../../interfaces/masters/company/get-companyDetails';
import { IGetComapnyDocuments } from '../../interfaces/masters/company/get-documents';
import { IAddUpdateBasicInfoModel } from '../../models/masters/company/basicInfo-model';
import { ICommonResponse } from '../../interfaces/common/common-response';
import { IAddUpdateBankDetailsModel } from '../../models/masters/company/bankDetails.model';
import { IAddUpdateDocumentModel } from '../../models/masters/company/documents-model';
import { USER_TYPE } from '../../constants/common-constant';
import { IGetVendorDocument } from '../../interfaces/user/vendor/get-document';

;
@Injectable({
  providedIn: 'root'
})
export class companyService{ 

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;
  
 
 getComapnyDetails(id: Number, statusEnumId : Number): Observable<IGetCompanyDetails> {
  const url = this.serverEndPoint + 'getCompanyDetails?companyId='+ id + '&statusEnumId=0' + statusEnumId;  
  return this.http.get<IGetCompanyDetails>(url);
}
getBankDetails(id: Number, statusEnumId : Number): Observable<IGetCompanyDetails> {
  const url = this.serverEndPoint + 'getCompanyBanksDetails?companyId='+ id + '&statusEnumId=' + statusEnumId;  
  return this.http.get<IGetCompanyDetails>(url);
}
      
getComapnyDocuments(id: Number , statusEnumId : Number): Observable<IGetComapnyDocuments> {
    const url = this.serverEndPoint + 'getCompanyDocumentsDetails?companyId=' + id + '&statusEnumId='+ statusEnumId;
    return this.http.get<IGetComapnyDocuments>(url);
  }

  addUpdateBasicInfo(model:IAddUpdateBasicInfoModel): Observable<any> {
    const url = this.serverEndPoint + 'addUpdateCompanyBasicDetails';
    console.log(url,',odel',model)
    return this.http.post<any>(url, model)
  }

  addUpdateBankInfo(model: IAddUpdateBankDetailsModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateCompanyBankDetails';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }

  addUpdateDocuments(model: IAddUpdateDocumentModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateCompanyDocumentDetails';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }
  getDocumentList(companyId:number): Observable<any> {
    const url = this.serverEndPoint +`getDocumentUserWiseForAllDocument?applicableForEnumId=${USER_TYPE.COMPANY}&vendorId=${companyId}`
    return this.http.get<IGetVendorDocument>(url);
  }


}
