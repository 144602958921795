import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetStockQuery } from '../../interfaces/stock-report/get-stock-report';
import { ICommonResponse } from '../../interfaces/common/common-response';

import { GetStockModel } from 'src/app/core/models/stock-report/getStockModel';
import { IGetStockHistoryData } from '../../interfaces/stock-report/get-stock-history-data';
import { IGetStockHistory } from '../../interfaces/stock-report/get-stock-history';
import { IGetDateWiseDetailModel } from '../../interfaces/stock-report/get-date-wise-detail-model.';
import { IGetDateWiseDetailModelData } from '../../interfaces/stock-report/get-date-wise-detail-model-data';
import { ModelDateWise } from '../../models/opening-stock/date-model';
import { IGetCurrentStock } from '../../interfaces/stock-report/current-stock/get-current-stock';


@Injectable({
    providedIn: 'root'
  })
  export class ReportStockService {
  
    constructor(private http: HttpClient) { }
    serverEndPoint = environment.apiUrl;


    getShopTableData(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
        const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
        return this.http.get<IGetShopTable>(url);
      }
      // getShopWiseStockQuery(fromDate: string,toDate: string,shopId:Number): Observable<IGetStockQuery> {
      //   const url = this.serverEndPoint + 'getOpeningStockHistoryDateWise?fromDate='+ fromDate + '&toDate='+ toDate + '&shopId=' + shopId;
      //   return this.http.get<IGetStockQuery>(url);
      // }

      getShopWiseStockQuery(GetStock: IGetStockHistory):Observable<IGetStockHistoryData> {
        const url = this.serverEndPoint + 'getOpeningStockHistoryDateWise'
        // + fromDate + '&toDate='+ toDate + '&shopId=' + shopId;
        return this.http.post<IGetStockHistoryData>(url,GetStock);
      }

      
  
      getDateWiseStockQueryModel(GetStock: ModelDateWise):Observable<IGetDateWiseDetailModelData> {
        const url = this.serverEndPoint + 'getStockModelDetails'
        // + fromDate + '&toDate='+ toDate + '&shopId=' + shopId;
        return this.http.post<IGetDateWiseDetailModelData>(url,GetStock);
      }
     


      // CURRENT STOCK
      getCurrentStockTableData(): Observable<IGetCurrentStock> {
        const url = this.serverEndPoint + 'getStockModel?statusEnumId=1';
        return this.http.get<IGetCurrentStock>(url);
      }
  
  
  }