import { ICategoryMaster } from "src/app/core/interfaces/inventory/category/category-master";

export class CategoryMaster implements ICategoryMaster {
  categoryId: Number;
  name: string;
  shortDescription: string;
  description: string;
  image: string;
  statusEnumId: Number;
  remark: string;
  actionByLoginUserId: Number;
  actionByUserTypeEnumId: Number;
  actionOnDate: string;
}

