import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../interfaces/common/common-response';
import { ICrNoteList } from '../../interfaces/cr-dr/cr-note-list/cr-note-list';
import { ICrNoteviewReturn } from '../../interfaces/cr-dr/cr-note-view/cr-noteview-return';
import { IGetInvoiceListId } from '../../interfaces/cr-dr/getinvoiceListForSaleReturn/get-invoice-list-id';
import { IGetInvoiceDetail } from '../../interfaces/order/order-management/get-invoiceDetail';
import { IGetVendor } from '../../interfaces/purchase/purchase-order/vendor';
import { IGetInvoices } from '../../interfaces/sales/invoices/get-invoices';
import { ModelCashDiscountCrNote } from '../../models/cr-dr-note/cashDiscountCrModel';
import { ModelRateDiffCrNote } from '../../models/cr-dr-note/RateDiffCrNoteModel';
import { ModelSaleReturnCrNote } from '../../models/cr-dr-note/saleReturnCrNoteModel';

@Injectable({
  providedIn: 'root'
})
export class CrDrServiceService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;

  // SALES RETURN CREDIT NOTE 
  createSaleRetunCreditNote(model:ModelSaleReturnCrNote):Observable<ICommonResponse>{
    const url = this.serverEndPoint + 'addCreditNoteForSaleReturn';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }


  // GET INVOICE DETAIL BY INVOICE ID SALES RETURN
  getInvoiceDetailData(invoiceId:Number,statusEnumId:Number,invoiceStatusEnumId:Number,shopId:Number):Observable<IGetInvoiceDetail>{
    const url = this.serverEndPoint + 'getInvoiceOrderDetail?invoiceId='+ invoiceId +'&statusEnumId='+ 
    statusEnumId +'&invoiceStatusEnumId='+ invoiceStatusEnumId +'&shopId=' + shopId 
    return this.http.get<IGetInvoiceDetail>(url);
  }


  // GET INVOICE DETAIL BY INVOICE ID SALES RETURN
    getInvoiceListForSaleReturn(customerId:Number):Observable<IGetInvoiceListId>{
      const url = this.serverEndPoint + 'getSalesReturnInvoiceIdForSalesCreditNote?customerId='+ customerId ;
      return this.http.get<IGetInvoiceListId>(url);
    }


// GET CREDIT NOTE LIST 
  getCreditNoteList(voucherId:Number,statusEnumId:Number,invoiceId:Number, shopId:Number, customerId:Number):Observable<ICrNoteList>{
    const url = this.serverEndPoint + 'getCreditNoteList?voucherId='+ voucherId +'&statusEnumId='+statusEnumId+'&invoiceId='+invoiceId+'&shopId='+shopId+'&customerId='+customerId ;
    return this.http.get<ICrNoteList>(url);
  }


  // GET CREDIT NOTE VIEW DETAIL
  getCreditNoteview(voucherId:Number,statusEnumId:Number,invoiceId:Number, shopId:Number,customerId:Number):Observable<ICrNoteviewReturn>{
    const url = this.serverEndPoint + 'getCreditNoteDetails?voucherId='+ voucherId +'&statusEnumId='+statusEnumId+'&invoiceId='+invoiceId+'&shopId='+shopId+'&customerId='+customerId ;
    return this.http.get<ICrNoteviewReturn>(url);
  }

  // CASH DISCOUNT CREDIT NOTE 
  creatCashDiscountCreditNote(model:ModelCashDiscountCrNote):Observable<ICommonResponse>{
    const url = this.serverEndPoint + 'addCreditNoteForCashDiscountOnInvoice';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }

  // CASH DISCOUNT CREDIT NOTE iNVOICE LIST   SALES > INVOICE 
  getInvoiceListForPayment(invoiceId: Number,statusEnumId: Number,invoiceStatusEnumId:Number,shopId:Number,invoicePaymentStatus:Number,customerId:Number): Observable<IGetInvoices> {
    const url = this.serverEndPoint + 'getInvoiceListForPayment?invoiceId='+ invoiceId + '&statusEnumId='+ statusEnumId
     + '&invoiceStatusEnumId=' + invoiceStatusEnumId + '&shopId=' + shopId + '&invoicePaymentStatus='+ invoicePaymentStatus + '&customerId=' + customerId;
    return this.http.get<IGetInvoices>(url);
  }


  // CASH DISCOUNT CREDIT NOTE 
  creatRateDiffCreditNote(model:ModelRateDiffCrNote):Observable<ICommonResponse>{
    const url = this.serverEndPoint + 'addCreditNoteForRateDiscountOnInvoiceProduct';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }

  //FUNCTION FOR GET RETURN CUSTOMER LIST
  getRturnCustomerlist(customerId:Number):Observable<ICrNoteviewReturn>{
    const url = this.serverEndPoint + 'getCustomerIdForSalesReturnCreditNote?customerId=' + customerId
        return this.http.get<ICrNoteviewReturn>(url);
  }

  //FUNCTION FOR GET VENDOR PO WISE
  getVendorPOWise(): Observable<IGetVendor>{
    const url = this.serverEndPoint + 'GetVendorPOWise'
      return this.http.get<IGetVendor>(url);
     }
  
}
