import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetProduct } from '../../interfaces/inventory/product/product';
import { IOpeningStockModel } from '../../models/opening-stock/opening-stock-model';



@Injectable({
  providedIn: 'root'
})
export class OpeningService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getShopTableData(mainshopId: Number, shopId: Number, statusEnumId: Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId=' + mainshopId + '&shopId=' + shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }


  getProductList(loginUserId: number, publishedStatusEnumId: number, statusEnumId: number): Observable<IGetProduct> {
    const url = this.serverEndPoint + 'GetProductForTable?LoginUserId=' + loginUserId + '&publishedStatusEnumId=' + publishedStatusEnumId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetProduct>(url);
  }

  addUpdateOpeningStock(openingStockModel: IOpeningStockModel): Observable<any> {
    const url = this.serverEndPoint + 'addUpdateOpeningStock';
    console.log(url, ',odel', openingStockModel)
    return this.http.post<any>(url, openingStockModel)
  }





}