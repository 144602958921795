import { IChallanToInvoice } from "src/app/core/interfaces/order/order-management/challan/challan-to-invoice";


export class ModelChallanToInvoice implements IChallanToInvoice{
    orderId: number;
    customerId: number;
    subTotal: number;
    cartId: number;
    discountAmount: number;
    orderDate: string;
    totalTax: number;
    taxAmount: number;
    otherCharges: number;
    couponCodeOrGiftCard: string;
    shopId: number;
    shopType: number;
    orderType: number;
    orderName: string;
    paymentType: string;
    deliveryAddressId: number;
    actionbyLoginUserId: number;
    actionbyUserTypeEnumId: number;
    statusEnumId: number;
    actionOnDate: string;
    challanId:number;
    orderDetail: { product_id: number; order_detail_id: number; mrp: number; no_of_product: number; unit_price: number; }[];

}