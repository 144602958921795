import { BrandMaster } from './../../../models/inventory/brand/brand-master-model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetBrandCard } from '../../../interfaces/inventory/brand/brand-card';
import { IGetBrandGrade } from '../../../interfaces/inventory/brand/brand-grade';
import { IGetGradeDetails } from '../../../interfaces/inventory/brand/grade-details';
import { AddNewGrade } from './../../../models/inventory/brand/add-new-grade-model';
import { UpdatePercentage } from '../../../models/inventory/brand/update-percentage-model';
import { IgetBrandPercentageRemarksData } from '../../../interfaces/inventory/brand/brand-percentage-remark-data';
import { IgetBrandPercentageRemarks } from '../../../interfaces/inventory/brand/brand-percentage-remark';
import { GradeWithPercentageModel } from 'src/app/core/models/inventory/brand/grade-with-per-model';
;
@Injectable({
  providedIn: 'root'
})
export class BrandService { 

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;
  
 
 getBrandGradeList(id: Number): Observable<IGetBrandGrade> {
  const url = this.serverEndPoint + 'getGrade?brandId=' + id;
  return this.http.get<IGetBrandGrade>(url);
}
      
  getBrandCardList(): Observable<IGetBrandCard> {
    const url = this.serverEndPoint + 'getBrandForCard';
    return this.http.get<IGetBrandCard>(url);
  }

  addUpdateBrand(brandModel: BrandMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addBrand';
    console.log(url,',odel',brandModel)
    return this.http.post<ICommonResponse>(url, brandModel)
  }

  UpdateBrandName(brandModel: BrandMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'updateBrandName';
    console.log(url,',odel',brandModel)
    return this.http.post<ICommonResponse>(url, brandModel)
  }

  addNewGrade(brandModel: AddNewGrade): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addNewGrade';
    console.log(url,',odel',brandModel)
    return this.http.post<ICommonResponse>(url, brandModel)
  }

  getGradeWithPercentage(gradewithPerModel:GradeWithPercentageModel): Observable<any> {
    const url = this.serverEndPoint + 'GetGradeWithPercentage'
    console.log(url,',odel',gradewithPerModel)
    return this.http.post<any>(url, gradewithPerModel);
  }

  updateGradePercentage(brandModel: UpdatePercentage): Observable<ICommonResponse>{
    const url = this.serverEndPoint + 'updateGradePercentage';
    console.log(url,',odel',brandModel)
    return this.http.post<ICommonResponse>(url, brandModel)
  }

  getBrandPercentageRemarks(brandId:Number, gradeName:string): Observable<IgetBrandPercentageRemarks> {
    const url = this.serverEndPoint + 'getBrandPercentageRemarks?brandId=' + brandId + '&gradeName=' + gradeName;
    return this.http.get<IgetBrandPercentageRemarks>(url);
  }

  getBrandwiseGradeDetail(customerId:Number): Observable<any> {
    const url = this.serverEndPoint + 'getBrandDetailForUser?UserId=' + customerId ;
    return this.http.get<any>(url);
  }





}
