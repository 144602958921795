import { TypeMaster } from './../../models/inventory/type/type-master-model';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment'
import { IGetType } from '../../interfaces/inventory/type/type';
import { ICommonResponse } from '../../interfaces/common/common-response';
import { IGetActiveCategory,  } from '../../interfaces/inventory/category/active-category-data';
import { IGetActiveTax } from '../../interfaces/inventory/tax/active-tax-data';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getCategoryList(): Observable<IGetActiveCategory> {
    const url = this.serverEndPoint + 'getCategoryByLoginUserId?actionLoginUserId=0';
    return this.http.get<IGetActiveCategory>(url);
  }

  getTaxList(): Observable<IGetActiveTax> {
    const url = this.serverEndPoint + 'getTaxList';
    return this.http.get<IGetActiveTax>(url);
  }

  getTypeList(id: Number): Observable<IGetType> {
    const url = this.serverEndPoint + 'getType?inventory_type_id=' + id;
    return this.http.get<IGetType>(url);
  }

  addUpdateType(typeModel: TypeMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateType';
    return this.http.post<ICommonResponse>(url, typeModel)
  }


}
