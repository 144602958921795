import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetUserDetailTable } from 'src/app/core/interfaces/user/b2b-customer/get-userDetail-table';
import { IGetState } from 'src/app/core/interfaces/common/state-city/get-state';
import { IGetCity } from 'src/app/core/interfaces/common/state-city/get-city';
import { IGetPersonalInfo } from 'src/app/core/interfaces/user/b2b-customer/get-personInfo-list';
import { B2BPersonalInfoModel } from 'src/app/core/models/user/b2b-customer/personalInfo-model';
import { ICommonResponse } from 'src/app/core/interfaces/common/common-response';
import { IRejectAndApprovedModel } from 'src/app/core/models/user/b2b-customer/reject-approved-model';
import { IGetBrandDetails } from 'src/app/core/interfaces/user/b2b-customer/get-brand-detail-list';
import { IGradeAssociateModel } from 'src/app/core/models/user/b2b-customer/grade-associate-model';
import { IUserDocumentData } from 'src/app/core/interfaces/user/b2b-customer/document-data';
import { IGetUserDocument } from 'src/app/core/interfaces/user/b2b-customer/get-document';
import { USER_TYPE } from 'src/app/core/constants/common-constant';
import { ISetCraditLimitModel } from 'src/app/core/models/user/b2b-customer/set-cradit-limit-model';
import { IGetUserCreditDetails } from 'src/app/core/interfaces/user/b2b-customer/ger-userCreditDetails-list';

@Injectable({
  providedIn: 'root'
})
export class B2BCustomerService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;



  getusertDetailTableData(id: Number, partnerstatusEnumId: Number): Observable<IGetUserDetailTable> {
    const url = this.serverEndPoint + 'getUserDetailForTable?userId=' + id + '&becamePartnerStatusEnumId=' + partnerstatusEnumId;
    return this.http.get<IGetUserDetailTable>(url);
  }

  getStateList(id: Number): Observable<IGetState> {
    const url = this.serverEndPoint + 'getStates?country_id=' + id;
    return this.http.get<IGetState>(url);
  }

  getCityList(id: Number): Observable<IGetCity> {
    const url = this.serverEndPoint + 'getCities?state_id=' + id;
    return this.http.get<IGetCity>(url);
  }

  getPersonalinfoList(userId: Number,): Observable<IGetPersonalInfo> {
    const url = this.serverEndPoint + 'getUserDetailByUserId?userId=' + userId;
    return this.http.get<IGetPersonalInfo>(url);
  }

  AddCustomerToBecomePartner(personalInfoModel: B2BPersonalInfoModel): Observable<any> {
    const url = this.serverEndPoint + 'AddCustomerToBecomePartner';
    console.log(url,',odel',personalInfoModel)
    return this.http.post<any>(url, personalInfoModel)
  }

  rejectAndApproved(rejctAndApprovedModel: IRejectAndApprovedModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'PartnerApprovedAndRejectedByAdmin';
    console.log(url,',odel',rejctAndApprovedModel)
    return this.http.post<ICommonResponse>(url, rejctAndApprovedModel)
  }

  getBrandDetails(userId: Number): Observable<IGetBrandDetails> {
    const url = this.serverEndPoint + 'getBrandDetailForUser?UserId=' + userId;
    return this.http.get<IGetBrandDetails>(url);
  }

  addBrandAssociatedWithPartner(gradeAssociateModel: IGradeAssociateModel): Observable<any> {
    const url = this.serverEndPoint + 'addBrandAssociatedWithPartner';
    console.log(url,',odel',gradeAssociateModel)
    return this.http.post<ICommonResponse>(url, gradeAssociateModel)
  }


  addUpdateDocument(model: IUserDocumentData): Observable<any> {
    const url = this.serverEndPoint + 'addUserDocument';
    return this.http.post<ICommonResponse>(url, model)
  }

  getDocumentList(userId:number): Observable<any> {
    const url = this.serverEndPoint +`getDocumentUserWiseForAll?applicableForEnumId=${USER_TYPE.CUSTOMER}&userId=${userId}`
    return this.http.get<IGetUserDocument>(url);
  }

  SetCreditForPartner(setCradiLimitModel: ISetCraditLimitModel): Observable<any> {
    const url = this.serverEndPoint + 'SetCreditForPartner';
    return this.http.post<ICommonResponse>(url, setCradiLimitModel)
  }

  getUserCreditDetails(userId: Number, statusEnumId:Number): Observable<IGetUserCreditDetails> {
     const url = this.serverEndPoint + 'getUserCreditDetail?userId=' + userId + '&statusEnumId=' +  statusEnumId;
    return this.http.get<IGetUserCreditDetails>(url);
  }

  // getUserB2CDetailForTable?userId=0&becamePartnerStatusEnumId=19&limit=100&page=1
  // b2c usserList filter quntity
  getB2cUserListwithLimit(userId:any, PartnerStatusEnumId:number, limit:number, page:number ): Observable<any> {
    const url = this.serverEndPoint + 'getUserB2CDetailForTable?userId=' + userId + '&becamePartnerStatusEnumId=' + PartnerStatusEnumId+'&limit='+limit+'&page='+page;
   return this.http.get<any>(url);
  }


  // get B2B user List 
  getB2BUserListWithLimit(userId:any, PartnerStatusEnumId:number, limit:number, page:number ): Observable<any> {
    const url = this.serverEndPoint + 'getUserB2BDetailForTable?userId=' + userId + '&becamePartnerStatusEnumId=' + PartnerStatusEnumId+'&limit='+limit+'&page='+page;
   return this.http.get<any>(url);
  }
}
