import { ITypeMaster } from "src/app/core/interfaces/inventory/type/type-master";

export class TypeMaster implements ITypeMaster {
  inventoryTypeId: Number;
    categoryId: Number;
    taxId:number;
    name: string;
    shortDescription: string;
    description: string;
    image: string;
    statusEnumId: number;
    remark: string;
    loginUserId: number;
    userTypeEnumId: number;
    
}
