import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../interfaces/common/common-response';
import { IBackInvoice } from '../../interfaces/data-migration/backDateInvoice/invoice';
import { DataMigrationModel } from '../../models/data-migration/data-invoice-Model';

@Injectable({
  providedIn: 'root'
})
export class DataMigrationService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


   // CASH DISCOUNT CREDIT NOTE 
   createDataMigrationInvoice(model:DataMigrationModel):Observable<ICommonResponse>{
    const url = this.serverEndPoint + 'addUpdateInvoiceDetailForBackDate';
    console.log(url,',odel',model)
    return this.http.post<ICommonResponse>(url, model)
  }

  // get invoice detail back date
    getBackDateInvoiceDetail(id:Number):Observable<IBackInvoice> {
      const url = this.serverEndPoint + 'GetInvoiceForBackDate?invoiceId='+id+'&statusEnumId=0&invoiceStatusEnumId=0&shopId=0';
      return this.http.get<IBackInvoice>(url);
    }
}
