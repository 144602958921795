import { IInwardMaster, InwardDetail } from "src/app/core/interfaces/purchase/inward/inward-master";
export class InwardMaster implements IInwardMaster{
    inwardId:number; 
    po_id: Number;
    poId: Number;

    vendorId : number;
    inwaradDate : string; 
    invoiceNo : number;
    invoiceDate :string; 
    remark : string;
    statusEnumId:number;
    actionByLoginUserId:number;
    actionByUserTypeEnumId:number;
    shope_id: number 
    inwardDetail:Array<InwardDetail>
    
}