import { returnMaster } from "src/app/core/interfaces/sales/return/return-master";


export class returnMasterModel implements returnMaster {
    customerId: number;
    actionbyLoginUserId: number;
    actionbyUserTypeEnumId: number;
    statusEnumId: number;
    remark:string;
    invoiceId:string;

    ReturnDetail: Array<
        {
            return_id: number;
            return_type_enum_id: number;
            invoice_id: number;
            product_id: number;
            shop_id: number;
            return_quantity: string;
            reason_to_return: string;
            retunr_date: string;
        }
    >
}
