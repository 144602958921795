import { AddMultipleProduct } from "src/app/core/interfaces/sales/return/addMultipleProduct";


export class AddMultipleProductModel implements AddMultipleProduct {
   
        customerId: number;
        statusEnumId:number;
        invoiceId: number;
        productId : number;
        invoicePaymentStatus:number;
    
}