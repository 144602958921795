import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetUserDetailTable } from 'src/app/core/interfaces/user/b2b-customer/get-userDetail-table';
import { IGetCustomersOrdersPending } from 'src/app/core/interfaces/sales/customer/get-orders-pending';
import { IGetInvoiceList } from 'src/app/core/interfaces/sales/customer/get-invoice-list';
import { IGetCustomerOrderDetails } from 'src/app/core/interfaces/order/order-allocation/get-customerOrderDetails';
import {  OrderInvoicePaymentModel } from 'src/app/core/models/sales/orderInvoicePayment';
import { ICommonResponse } from 'src/app/core/interfaces/common/common-response';
import { IOrderInvoiceDetail } from 'src/app/core/interfaces/sales/customer/order-invoice-detail';
import { IGetCustomersOrders } from 'src/app/core/interfaces/sales/customer/get-customersOrders';
import { IGetCustomerOrderId } from 'src/app/core/interfaces/sales/customer/receipt/get-customer-order-id';
import { IGetCustomerInvoiceId } from 'src/app/core/interfaces/sales/customer/receipt/get-customer-invoice-id';
import { IGetYearWise } from 'src/app/core/interfaces/sales/customer/sale-status/get-year-wise';
import { IGetMonthWise } from 'src/app/core/interfaces/sales/customer/sale-status/get-month-wise';
import { IGetInvoiceDetail } from 'src/app/core/interfaces/order/order-management/get-invoiceDetail';
import { IGetPaymentHistory } from 'src/app/core/interfaces/sales/invoices/get-payment-history';



@Injectable({
  providedIn: 'root'
})
export class customersService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getCustomerOrderedList(id: Number): Observable<IGetCustomersOrders> {
    const url = this.serverEndPoint + 'getCustomerOrderedList?becamePartnerStatusEnumId=' + id 
    return this.http.get<IGetCustomersOrders>(url);
  }

  getCustomerOrdersPendigData(orderId: Number, statusEnumId: Number, customerId: Number): Observable<IGetCustomersOrdersPending> {
    const url = this.serverEndPoint + 'getCustomerOrderCustomerWisePendingOrderTable?orderId=' + orderId + '&statusEnumId=' + statusEnumId
      + '&customerId=' + customerId;
    return this.http.get<IGetCustomersOrdersPending>(url);
  }

  getCustomerOrdersCompletedData(orderId: Number, statusEnumId: Number, customerId: Number): Observable<IGetCustomersOrdersPending> {
    const url = this.serverEndPoint + 'getCustomerWiseCompleteOrder?orderId=' + orderId + '&statusEnumId=' + statusEnumId
      + '&customerId=' + customerId;
    return this.http.get<IGetCustomersOrdersPending>(url);
  }


   getInvoiceList(invoiceId :Number,shopId: number,invoiceStatusEnumId:number): Observable<IGetInvoiceList> {
    const url = this.serverEndPoint + 'getInvoice?invoiceId=0&statusEnumId=1&invoiceStatusEnumId=66&shopId='+shopId
    return this.http.get<IGetInvoiceList>(url);
  }

  getCustomerordersDetail(orderId: number): Observable<IGetCustomerOrderDetails> {
    const url = this.serverEndPoint +
      'getCustomerOrder?orderId=' + orderId + '&statusEnumId=1';
    return this.http.get<IGetCustomerOrderDetails>(url);
  }

  addOrderInvoicePayment(PaymentModel : OrderInvoicePaymentModel):Observable<ICommonResponse>{
    const url = this.serverEndPoint +
    'addCustomerOrderInvoicePayment' ;
  return this.http.post<ICommonResponse>(url,PaymentModel);
  }

  getInvoiceDetail(invoiceId: number, shopId: number):Observable<IOrderInvoiceDetail>{
    const url = this.serverEndPoint +'getInvoiceOrderDetail?invoiceId='+invoiceId+'&statusEnumId=0&invoiceStatusEnumId=0&shopId='+shopId;
    return this.http.get<IOrderInvoiceDetail>(url);
  }

  getInvoiceTableData(invoiceId:Number,statusEnumId:Number,invoiceStatusEnumId:Number,shopId:Number):Observable<IGetInvoiceDetail>{
    const url = this.serverEndPoint + 'getInvoiceTable?invoiceId='+ invoiceId +'&statusEnumId='+ 
    statusEnumId +'&invoiceStatusEnumId='+ invoiceStatusEnumId +'&shopId=' + shopId 
    return this.http.get<IGetInvoiceDetail>(url);
 
  }
  getCustomerOrderId(orderId:number, customerId:number): Observable<IGetCustomerOrderId> {
    const url = this.serverEndPoint +'getCustomerOrderCustomerWise?orderId='+orderId+'&statusEnumId=1&customerId='+customerId+'&orderStatusEnumId=0';
    return this.http.get<IGetCustomerOrderId>(url);
  }
  getCustomerInvoiceid(orderId: number):Observable<IGetCustomerInvoiceId> {

    const url = this.serverEndPoint +'getInvoiceOrderWise?orderId='+orderId+'&statusEnumId=1';
    return this.http.get<IGetCustomerInvoiceId>(url);
  }

  // sale status: string

  getYearWise(customerId:number, year:number):Observable<IGetYearWise>{
    const url = this.serverEndPoint +'getSalesStatusReport?statusEnumId=1&customerId='+customerId+'&financialYearStart=%27'+year+'%27'
    return this.http.get<IGetYearWise>(url);
  }

  getMonthWise(customerId:number,month:string, year:number ): Observable<IGetMonthWise> {
    
    const url = this.serverEndPoint +'getInvoiceMonthWise?customerId='+customerId+'&monthYear=%27'+month+'/'+year+'%27&statusEnumId=1'
    return this.http.get<IGetMonthWise>(url);
  }


  getPaymentHisory(invoiceId:number,statusEnumId:number): Observable<IGetPaymentHistory> {
    const url = this.serverEndPoint + 'getPaymentHistoryInvoiceWise?invoiceId=' + invoiceId + '&statusEnumId=' + statusEnumId
    return this.http.get<IGetPaymentHistory>(url);
  }
  
  

}

