import { IOpeningStock } from "../../interfaces/opening-stock/opening-stock";

export class IOpeningStockModel implements IOpeningStock {
    userId:number;
    statusEnumId:number;
    actionByLoginUserId:number;
    actionByUserTypeEnumId:number;
    openingStockDate :string;
    openingDetail: Array<
    {
        product_id : number;
        opening_stock_quantity: number;
        shop_id : number;
    }
    >
}
    