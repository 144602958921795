import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetVendor } from 'src/app/core/interfaces/purchase/purchase-order/vendor';
import { IGetInwardTable } from 'src/app/core/interfaces/purchase/inward/inward-table';
import { IGetPoList } from 'src/app/core/interfaces/purchase/inward/get-poList';
import { IGetPoDetails } from 'src/app/core/interfaces/purchase/purchase-order/get-po-details';
import { IGetPaidUnPaidBills } from 'src/app/core/interfaces/purchase/purchase-bills/create-bill/get-paidUnpaidbills';
import { IGetPoListForBills } from 'src/app/core/interfaces/purchase/purchase-bills/create-bill/get-poList';
import { IGetInwardDetailForTableModel } from 'src/app/core/models/purchase/purchase-bill/create-bill/inwardDetailsForTable-model';
import { PurchaseBillModel } from 'src/app/core/models/purchase/purchase-bill/create-bill/addUpdate-purchaseBill-model';
import { InwardListForBillModel } from 'src/app/core/models/purchase/purchase-bill/create-bill/inwardBillList-model';
import { IGetInwardlistByVendor } from 'src/app/core/interfaces/purchase/purchase-bills/inwardList/get-inwardlist-by-vendor';




@Injectable({
  providedIn: 'root'
})
export class PurchaseBillsService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getVendorPOWise(): Observable<IGetVendor>{
    const url = this.serverEndPoint + 'GetVendorPOWise'
      return this.http.get<IGetVendor>(url);
     }


     // inward list by vendorid
  getInwardListByVendorId(vendorId:number): Observable< IGetInwardlistByVendor>{
  const url = this.serverEndPoint + 'getInwardListVendorWise?statusEnumId=1&vendor_id='+vendorId
    return this.http.get< IGetInwardlistByVendor>(url);
    }


    //  getInwardListVendorWise?statusEnumId=1&vendor_id=76
  

  getPoData(statusEnumId: Number, vendorId: Number): Observable<IGetPoList> {
    const url = this.serverEndPoint + 'getPOListForInward?statusEnumId=' + statusEnumId +'&vendorId=' + vendorId;
    return this.http.get<IGetPoList>(url);
  }

  getInwardTableData(id : number): Observable<IGetInwardTable> {
    const url = this.serverEndPoint + 'getInwardForTable?statusEnumId='+ id;
    return this.http.get<IGetInwardTable>(url);
  }

  getPaidUnpaidBillList(billId: Number, statusEnumId: Number,paymentStatusEnumId:number): Observable<IGetPaidUnPaidBills> {
    const url = this.serverEndPoint + 'getPoBillListController?billId='+ billId + '&statusEnumId=' +statusEnumId + '&paymentStatusEnumId=' + paymentStatusEnumId
    return this.http.get<IGetPaidUnPaidBills>(url);
  }

  getPoListForBills(statusEnumId: Number, vendorId: Number): Observable<IGetPoListForBills> {
    const url = this.serverEndPoint + 'getPOListForBill?statusEnumId=' + statusEnumId + '&vendorId='+ vendorId
    return this.http.get<IGetPoListForBills>(url);
  }


  InwardDetailsForTable(inwardDetailsModel:IGetInwardDetailForTableModel ): Observable<any> {
    const url = this.serverEndPoint + 'getPoInwardDetailsForBill';
    console.log(url,',odel',inwardDetailsModel)
    return this.http.post<ICommonResponse>(url, inwardDetailsModel)
  }

  addUpdatePurchaseBill(purchaseModel:PurchaseBillModel ): Observable<any> {
    const url = this.serverEndPoint + 'addUpdatePurchaseOrderInwardBill';
    console.log(url,',odel',purchaseModel)
    return this.http.post<ICommonResponse>(url, purchaseModel)

  }

  getinwardListForBill(purchaseModel:InwardListForBillModel ): Observable<any> {
    const url = this.serverEndPoint + 'getInwardListForBill';
    console.log(url,',odel',purchaseModel)
    return this.http.post<ICommonResponse>(url, purchaseModel)

  }
  

  getPoDetailsList(poId: Number,statusEnumId: Number):Observable<IGetPoDetails>{
    const url = this.serverEndPoint + 'getPurchaseOrderDetail?poId=' + poId +'&statusEnumId=' + statusEnumId
    return this.http.get<IGetPoDetails>(url);
  }

 
  
  


   
}