import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetVendor } from 'src/app/core/interfaces/purchase/purchase-order/vendor';
import { IGetInwardTable } from 'src/app/core/interfaces/purchase/inward/inward-table';
import { InwardMaster } from 'src/app/core/models/purchase/inword/inward-master-model';
import { IGetPoList } from 'src/app/core/interfaces/purchase/inward/get-poList';
import { IGetPoDetails } from 'src/app/core/interfaces/purchase/purchase-order/get-po-details';
import { IGetInwardDetails } from 'src/app/core/interfaces/purchase/inward/inward-details';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetPoBillDetails } from 'src/app/core/interfaces/purchase/purchase-bills/getPoBillDetails/get-po-bill-details';
import { PoInfoModel } from 'src/app/core/models/purchase/purchase-bill/payment-bill/get-poInfo-model';
import { AddPurchaseBillPaymentModel } from 'src/app/core/models/purchase/purchase-bill/payment-bill/add-paymentBill-model';
import { IGetlistBillPayment } from 'src/app/core/interfaces/purchase/bill-payment/getlist-bill-payment';
import { addPaymentMultipleBillModel } from 'src/app/core/models/purchase/bill-payment/bill-payment-model';
import { IAddPoBillPaymentByAdvanceModel } from 'src/app/core/models/purchase/bill-payment/addPoPaymentbyAdvance-model';




@Injectable({
  providedIn: 'root'
})
export class PurchaseBillPaymentService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  getVendorPOWise(): Observable<IGetVendor>{
    const url = this.serverEndPoint + 'GetVendorPOWise'
      return this.http.get<IGetVendor>(url);
     }
  

  getPoData(statusEnumId: Number, vendorId: Number): Observable<IGetPoList> {
    const url = this.serverEndPoint + 'getPOListForInward?statusEnumId=' + statusEnumId +'&vendorId=' + vendorId;
    return this.http.get<IGetPoList>(url);
  }

  getInwardTableData(id : number): Observable<IGetInwardTable> {
    const url = this.serverEndPoint + 'getInwardForTable?statusEnumId='+ id;
    return this.http.get<IGetInwardTable>(url);
  }

  getInwardDetailData(statusEnumId: Number, inwardId: Number): Observable<IGetInwardDetails> {
    const url = this.serverEndPoint + 'getInwardDetail?statusEnumId=' + statusEnumId + '&inwardId=' + inwardId;
    return this.http.get<IGetInwardDetails>(url);
  }


  addUpdateInward(inwardModel: InwardMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateInwardDetail';
    console.log(url,',odel',inwardModel)
    return this.http.post<ICommonResponse>(url, inwardModel)
  }

  getPoDetailsList(poId: Number,statusEnumId: Number):Observable<IGetPoDetails>{
    const url = this.serverEndPoint + 'getPurchaseOrderDetail?poId=' + poId +'&statusEnumId=' + statusEnumId
    return this.http.get<IGetPoDetails>(url);
  }
  getShopList(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }

  getVendorList(id: Number): Observable<IGetVendor> {
    const url = this.serverEndPoint + 'GetVendorForTable?vendorId=' + id;
    return this.http.get<IGetVendor>(url);
  }

  getBillPaymentList(id: Number){
    const url = this.serverEndPoint + 'getPoBillDetails?billId=' + id + '&statusEnumId=1';
    return this.http.get<IGetPoBillDetails>(url);
  }

  

  getPoInfoDetails(poInfoModel:PoInfoModel): Observable<any> {
    const url = this.serverEndPoint + 'getPoInwardSummaryForBill';
    console.log(url,',odel',poInfoModel)
    return this.http.post<ICommonResponse>(url, poInfoModel)

  }

  getBankDetail(id:Number){
    const url = this.serverEndPoint + 'GetVendorBankDetail?vendor_id=' +id
    return this.http.get<any>(url);
  }

  addPaymentBill(paymentModel: AddPurchaseBillPaymentModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addPoPaymentForSingleBill';
    console.log(url,',odel',paymentModel)
    return this.http.post<ICommonResponse>(url, paymentModel)
  }
  

  getBillPaymentListForAll(id:Number){
    const url = this.serverEndPoint + 'getBillPaymentList?vendorId='+id+'&statusEnumId=1';
    return this.http.get<IGetlistBillPayment>(url);
  }
 

  addPaymentMultipleBill(paymentModel: addPaymentMultipleBillModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addPaymentMultipleBill';
    console.log(url,',odel',paymentModel)
    return this.http.post<ICommonResponse>(url, paymentModel)
  }

  addPaymentBillByAdvance(paymentbyadvanceModel: IAddPoBillPaymentByAdvanceModel): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addVendorPoBillPaymentByAdvance';
    console.log(url,',odel',paymentbyadvanceModel)
    return this.http.post<ICommonResponse>(url, paymentbyadvanceModel)
  }
   
}