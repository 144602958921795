//===============================================================================
// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Aman Mishra
// Original Date: 3 June 2021
//==============================================================================

import { CommonAlertComponent } from "./common-alert/common-alert.component"
import { HistoryTableComponent } from "./history-table/history-table.component"
import { SpinnerComponent } from "./spinner/spinner.component"
import { SubHeaderComponent } from "./sub-header/sub-header.component"
import { TableComponent } from "./table/table.component"
import { CardComponent } from "./card/card.component"
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component"
import { InvoiceComponent } from "./invoice/invoice.component"
import { NoDataComponent } from "./no-data/no-data.component"
import { PieComponent } from "./chart/pie/pie.component"
import { PdfInvoiceComponent } from "./pdf-invoice/pdf-invoice.component"
import { SalePriceComponent } from "./sale-price/sale-price.component"
import {TradeDiscountComponent} from '../trade-discount/trade-discount.component'

export const SharedComponents =[
  SpinnerComponent,
  CommonAlertComponent,
  SubHeaderComponent,
  TableComponent,
  HistoryTableComponent,
  CardComponent,
  ConfirmationModalComponent,
  InvoiceComponent,
  NoDataComponent,
  PieComponent,
  PdfInvoiceComponent,
  SalePriceComponent,
  TradeDiscountComponent
]
export {
  SpinnerComponent,
  CommonAlertComponent,
  SubHeaderComponent,
  TableComponent,
  HistoryTableComponent,
  CardComponent,
  ConfirmationModalComponent,
  NoDataComponent,
  PieComponent,
  PdfInvoiceComponent,
  SalePriceComponent

}
