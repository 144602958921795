import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../../../interfaces/common/common-response';
import { IGetVendor } from 'src/app/core/interfaces/purchase/purchase-order/vendor';
import { IGetInwardTable } from 'src/app/core/interfaces/purchase/inward/inward-table';
import { InwardMaster } from 'src/app/core/models/purchase/inword/inward-master-model';
import { IGetPoList } from 'src/app/core/interfaces/purchase/inward/get-poList';
import { IGetPoDetails } from 'src/app/core/interfaces/purchase/purchase-order/get-po-details';
import { IGetInwardDetails } from 'src/app/core/interfaces/purchase/inward/inward-details';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';




@Injectable({
  providedIn: 'root'
})
export class InwardService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;


  // getVendorList(id: Number): Observable<IGetVendor> {
  //   const url = this.serverEndPoint + 'GetVendorForTable?vendorId=' + id;
  //   return this.http.get<IGetVendor>(url);
  // }

  getVendorPOWise(): Observable<IGetVendor>{
    const url = this.serverEndPoint + 'GetVendorPOWise'
      return this.http.get<IGetVendor>(url);
     }
  

  getPoData(statusEnumId: Number, vendorId: Number): Observable<IGetPoList> {
    const url = this.serverEndPoint + 'getPOListForInward?statusEnumId=' + statusEnumId +'&vendorId=' + vendorId;
    return this.http.get<IGetPoList>(url);
  }

  getInwardTableData(id : number): Observable<IGetInwardTable> {
    const url = this.serverEndPoint + 'getInwardForTable?statusEnumId='+ id;
    return this.http.get<IGetInwardTable>(url);
  }

  getInwardDetailData(statusEnumId: Number, inwardId: Number): Observable<IGetInwardDetails> {
    const url = this.serverEndPoint + 'getInwardDetail?statusEnumId=' + statusEnumId + '&inwardId=' + inwardId;
    return this.http.get<IGetInwardDetails>(url);
  }


  addUpdateInward(inwardModel: InwardMaster): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'addUpdateInwardDetail';
    console.log(url,',odel',inwardModel)
    return this.http.post<ICommonResponse>(url, inwardModel)
  }

  getPoDetailsList(poId: Number,statusEnumId: Number):Observable<IGetPoDetails>{
    const url = this.serverEndPoint + 'getPurchaseOrderDetail?poId=' + poId +'&statusEnumId=' + statusEnumId
    return this.http.get<IGetPoDetails>(url);
  }
  getShopList(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }

  getPoDetailsListMultipleId(poId): Observable<any> {  //ICommonResponse
    const url = this.serverEndPoint + 'getPODetailForIn';
    console.log(url,',odel',poId)
    return this.http.post<any>(url, poId)  //ICommonResponse
  }


   
}